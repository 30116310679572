// FIXME: あまりにも意味不明のコードなので、クラス化する。
import Bowser from 'bowser'

let count = 0
let timerId

const waitUntilRefleshed = function (unloaded) {
  const bowser = Bowser.detect(window.navigator.userAgent)
  if(bowser.msie) {
    // FIXME: IEではwindow.openerにイベント入れることができないので、苦肉の策
    let isReload = false
    const unloadTimeId = setInterval(() => {
      const currentOpenerScrollY = (window.opener.scrollY || window.opener.pageYOffset)
      if (currentOpenerScrollY == 0) isReload = true
      if (!isReload) return
      clearInterval(unloadTimeId)
      unloaded()
    }, 500)
  } else {
    window.opener.addEventListener('unload', (e) => { unloaded() })
  }
}

const watchOpenerReloaded = function(openerScrollY, btnClose) {
  const currentOpenerScrollY = window.opener && (window.opener.scrollY || window.opener.pageYOffset)
  // 10秒以上、更新が終わらない場合、ボタンを表示する
  if (count > 20 || currentOpenerScrollY === null || currentOpenerScrollY == openerScrollY) {
    clearInterval(timerId)
    btnClose.disabled = ''
    btnClose.innerText = btnClose.dataset.preparedLabel
    return
  }
  window.opener.scrollTo(0, openerScrollY)
  count++
}

const scrollAndClose = function (openerScrollY) {
  if (window.opener && !window.opener.closed) window.opener.scrollTo(0, openerScrollY)
  window.close()
}

const isOpenerExists = function () {
  try {
    const tryGetParentName = window.opener.name
  } catch(e) {
    // 親 Window が存在しない場合は Cannot read property 'name' of null が発生する
    // 親 Window が別ドメインに居た場合は DOMException: Blocked a frame with origin が発生する
    return false
  }
  return true
}

export default () => {
  const btnClose = document.getElementById("btn-close-window")
  if (!btnClose) {
    return
  }

  // 直URLで画面を開いた時、Chromeでは閉じることができないので物件一覧に戻るようにした
  if (!isOpenerExists()) {
    // 直URLで画像登録画面を開いた時は閉じるボタンを表示しない
    // IE11では .remove() が動かないので素直にjQueryを使う。
    if (btnClose) { $(btnClose).remove() }

    const btnNewRegisterPlace = document.getElementById("btn-new-register-place")
    const btnBackPlaceMapPoints = document.getElementById("btn-back-place-map-points")
    const isPlaceInformationSaved = !btnNewRegisterPlace && !btnBackPlaceMapPoints
    if (isPlaceInformationSaved) {
      // 物件一覧にリダイレクトする、ただし……
      // 新規登録ボタンが存在する場合はリダイレクトしない
      // 撮影点一覧画面へ戻るボタンがある場合はリダイレクトしない
      window.location.href = "/places"
    }
    return
  }

  // 新規タブで開いた場合は、物件へ戻るボタンとリダイレクトメッセージは非表示にする
  const redirectMessage = document.getElementById("redirect-message")
  if (redirectMessage) { $(redirectMessage).remove() }
  const btnBackPlaces = document.getElementById("btn-back-places")
  if (btnBackPlaces) { $(btnBackPlaces).remove() }

  const openerScrollY = (window.opener.scrollY || window.opener.pageYOffset)
  btnClose.addEventListener('click', () => { scrollAndClose(openerScrollY) })

  window.opener.location.reload()
  waitUntilRefleshed(() => {
    timerId = setInterval(watchOpenerReloaded.bind(null, openerScrollY, btnClose), 500)
  })
}
