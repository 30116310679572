console.log('setup/request-client')

// axios
import axios from 'axios'
import { setup } from 'axios-cache-adapter'

// キャッシュ機能つきのインスタンスを作成（ただし、デフォルトは無効）
// リクエスト単位でキャッシュ有効にする場合は、リクエスト時のオプションに { cache: { ignoreCache: false } } を指定する。
// ex) this.$http.get(url, { cache: { ignoreCache: false } })
const clinet = setup({
  cache: {
    maxAge: 3600 * 1000, // ミリ秒単位
    ignoreCache: true,
    debug: process.env.NODE_ENV !== 'production'
  }
})

// 全てのリクエストに X-Requested-With: XMLHttpRequest ヘッダを追加する。
clinet.interceptors.request.use(config => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  return config
})

Nurve.$http = clinet
