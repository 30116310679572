console.log('setup/error-handling')

import * as Airbrake from '@airbrake/browser'

if(process.env.AIRBRAKE_API_KEY || process.env.GROUP_CALLS_AIRBRAKE_API_KEY) {
  let projectId = process.env.AIRBRAKE_PROJECT_ID 
  let projectKey = process.env.AIRBRAKE_API_KEY

  // グループ通話の場合、AirbrakeのKeyとIdを変更
  if (/^\/g\//.test(location?.pathname)) {
    projectId = process.env.GROUP_CALLS_AIRBRAKE_PROJECT_ID || projectId
    projectKey = process.env.GROUP_CALLS_AIRBRAKE_API_KEY || projectKey
  }

  const notifier = new Airbrake.Notifier({
    projectId,
    projectKey,
    host: process.env.AIRBRAKE_HOST || 'https://api.airbrake.io',
    instrumentation: {
      console: false,
    }
  })

  notifier.addFilter((notice) => {
    notice.context.environment = process.env.RAILS_ENV || process.env.NODE_ENV
    return notice
  })

  window.airbrake = notifier

  const NotifyAirbrakeLogHook = {
    run (event) {
      if(event.level == 'error' || event.level == 'warn') {
        notifier.notify({
          error: event.argumentArray,
          context: { severity: event.level }
        })
      }
    }
  }
  Nurve.log.apply({
    afterHooks: [NotifyAirbrakeLogHook]
  })
}

window.addEventListener('error', e => {
  Nurve.log.error(e)
})

if(process.env.NODE_ENV !== 'production') {
  Nurve.bus.on('*', (eventName, data) => {
    Nurve.log.debug('NurveBus', eventName, data)
  })
}
