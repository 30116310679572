// @ts-nocheck

import loadImage from 'image-promise'

import Base from './base'

import { dig } from '~/shared/global_functions'

export default class extends Base {
  static TYPES = {
    image: ['image', 'celestial_sphere_image', 'fisheye_250_image', 'swing_image', 'fisheye_180_image', 'chipping_fisheye_image', 'photo_movie'],
    static_image: ['image', 'swing_image', 'photo_movie'],
    vr_iamge: ['celestial_sphere_image', 'fisheye_250_image', 'fisheye_180_image', 'chipping_fisheye_image'],
    video: ['video', 'celestial_sphere_video', 'fisheye_250_video'],
    static_video: ['video'],
    panorama_only: ['celestial_sphere_image']
  }

  static get nullInstance() {
    return new this({}).setNull()
  }

  static create(data: any, point = null) {
    if(data === null || data === undefined) {
      return this.nullInstance
    }
    if(data instanceof this) {
      return data
    }

    return new this(data, point)
  }

  constructor(data: any = {}, point = null) {
    super()

    this.point = point

    Object.assign(this, data)
  }

  get processingType() {
    const tags = dig(this.meta_data, 'common.processing_tags', [])

    const homeStagingTagged = tags.includes('home_staging')
    const itemRemovalTagged = tags.includes('item_removal')
    if(homeStagingTagged && itemRemovalTagged) {
      return 'item_removal_home_staging'
    }
    if(homeStagingTagged) {
      return 'home_staging'
    }
    if(itemRemovalTagged) {
      return 'item_removal'
    }

    return ''
  }

  static isImageType(type: string): boolean {
    return this.TYPES.image.includes(type)
  }

  get isImageType(): boolean {
    return this.constructor.isImageType(this.type)
  }

  static isStaticImageType(type: string): boolean {
    return this.TYPES.static_image.includes(type)
  }

  get isStaticImageType(): boolean {
    return this.constructor.isStaticImageType(this.type)
  }

  static isVrImageType(type: string): boolean {
    return this.TYPES.vr_iamge.includes(type)
  }

  get isVrImageType(): boolean {
    return this.constructor.isVrImageType(this.type)
  }

  static isVideoType(type: string): boolean {
    return this.TYPES.video.includes(type)
  }

  get isVideoType(): boolean {
    return this.constructor.isVideoType(this.type)
  }

  static isStaticVideoType(type: string): boolean {
    return this.TYPES.static_video.includes(type)
  }

  get isStaticVideoType(): boolean {
    return this.constructor.isStaticVideoType(this.type)
  }

  static isPanoramaOnlyType(type: string): boolean {
    return this.TYPES.panorama_only.includes(type)
  }

  get excuseText(): string {
    let text

    switch (this.processingType) {
      case 'item_removal_home_staging':
        text = '※現況の物件画像にCGによる処理が施されています。<br>設置してある家具はCGで実際には設置されておりません。'
        break
      case 'home_staging':
        text = '※現況の物件画像にCGにより家具が設置してあります。<br>実際には家具は設置されておりません。'
        break
      case 'item_removal':
        text = '※CGによるリフォームイメージです。リフォームには別途費用が発生いたします。<br>実際のお部屋は家具等が配置されており、キズや汚れ等がある場合がございます。'
        break
      default:
        text = ''
        break
    }

    return text
  }

  static async detectItemType(file) {
    if (/^image\/jpeg/.test(file.type) || /^image\/png/.test(file.type)) {
      const url = URL.createObjectURL(file)
      const image = await loadImage(url)

      const aspect = image.width / image.height
      return (aspect > 1.99 && aspect < 2.01) ? 'celestial_sphere_image' : 'image'

    } else if (/^video\/mp4/.test(file.type)) {
      return 'celestial_sphere_video'

    } else {
      return null
    }
  }
}
