import Url from 'url-parse'
import Bowser from 'bowser'
import Modernizr from 'modernizr'
import HtmlMeta from 'html-meta'
import clone from 'clone'

import Account from '~/setup/models/account'
import BowserAccessor from '~/use_case_supports/states/bowser_accessor'

export default {
  namespaced: true,

  state: {
    account: new Account(),
    environments: {},
    url: new Url(window.location.href, true),
    meta: new HtmlMeta(),
    bowser: Bowser.detect(window.navigator.userAgent),
    userAgentData: {},
    modernizr: Modernizr,
    locales: {},
    Rails: window.Rails,
    injected: clone(window.gon || {}) // vuexの警告発生を避けるため clone する。
  },

  getters: {
    currentAccount: (state) => {
      return state.account
    },

    currentOrganizationGroup: (state) => {
      return state.account.organization_group
    },

    defaultLocale: (state, getters) => {
      const currentOrganizationGroup = getters.currentOrganizationGroup
      if(currentOrganizationGroup === undefined) {
        return 'ja'
      }

      return currentOrganizationGroup.fetchSetting('i18n.locale', 'ja')
    }
  },

  mutations: {
    setAccount(state, data) {
      if (data.models.account) {
        state.account = new Account(data.models.account)
      }
    },

    setEnvironments(state, data) {
      state.environments = data.environments
    },

    setLocales(state, data) {
      if (data.locales) {
        state.locales = data.locales
      }
    },

    setUserAgentData(state, data) {
      state.userAgentData = data
    }
  },

  actions: {
    fetch(context) {
      context.dispatch('fetchUserAgentData')

      return Nurve.$http.get('/internal_api/contexts').then(response => {
        context.commit('setAccount', response.data)
        context.commit('setEnvironments', response.data)
        context.commit('setLocales', response.data)

        return context.dispatch('setFeatureFlags')
      })
    },

    fetchUserAgentData(context) {
      // userAgentDataがサポートされている
      if (navigator.userAgentData) {
        navigator.userAgentData
          .getHighEntropyValues(['platform', 'platformVersion', 'architecture', 'model', 'uaFullVersion'])
          .then(result => context.commit('setUserAgentData', result))
        return
      }

      // safariなど、サポートされていない端末向け (再実装が面倒なのでコメントアウトしておきます)
      // const osname = BowserAccessor.checkIpad ? 'iPad' : BowserAccessor.bowser.osname
      // context.commit('setUserAgentData', {
      //   platform: osname,
      //   platformVersion: BowserAccessor.bowser.osversion,
      //   architecture: null,
      //   model: `${osname} ${BowserAccessor.bowser.name}`,
      //   uaFullVersion: BowserAccessor.bowser.version,
      // })
    },

    setFeatureFlags(context) {
      const features = ['v:1', 'flat-video']

      if(!context.state.bowser.msie) {
        features.push('hls')
      }

      Nurve.$http.interceptors.request.use(config => {
        config.headers['X-NC-Features'] = features.join(' ')
        return config
      })
    },

    setCSRFToken(context, csrf_token) {
      // Vue.$http のインスタンスに X-CSRF-Token ヘッダを自動設定する。
      // 注: axios自体に middleware をかませてはいけない。（全体に影響してしまう。）
      Nurve.$http.interceptors.request.use(config => {
        config.headers['X-CSRF-Token'] = csrf_token
        return config
      })
    }
  },
}
