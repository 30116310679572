// @ts-nocheck

import Base from './base'
import Place from './place'
import Point from './point'

export default class extends Base {
  static get nullInstance() {
    return new this({}).setNull()
  }

  static create(data: any, place = null) {
    if(data === null || data === undefined) {
      return this.nullInstance
    }
    if(data instanceof this) {
      return data
    }

    return new this(data)
  }

  constructor(data: any = {}, place = null) {
    super()
    Object.assign(this, data)

    this.place = place
    this.points = (data.points || []).map(p => Point.create(p, this))
  }
}
