export default class {
  __isNull: boolean

  constructor() {
    this.__isNull = false
  }

  setNull() {
    this.__isNull = true
    return this
  }

  get isNull() {
    return this.__isNull
  }
}
