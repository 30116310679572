// @ts-nocheck

import Base from './base'
import Map from './map'
import OrganizationGroup from './organization_group'

export default class extends Base {
  static get nullInstance() {
    return new this({
      full_name: '',
      public: 'private'
    }).setNull()
  }

  static create(data: any) {
    if(data === null || data === undefined) {
      return this.nullInstance
    }
    if(data instanceof this) {
      return data
    }

    return new this(data)
  }

  constructor(data = {}) {
    super()
    Object.assign(this, data)

    if (data.organization_group) {
      this.organization_group = data.organization_group
    }
    if (data.organization_group_id) {
      this.organization_group = new OrganizationGroup({
        id: data.organization_group_id,
      })
    }

    this.maps = (data.maps || []).map(m => Map.create(m, this))
  }

  get isPublic() {
    return this.status === 'public'
  }
}
