// FIXME: コードがメンテナンスされずいろいろ破綻している。作り直しが必要。
// 歴史的理由で ng_word_form.js となっているが、実際には input element

class NGWords {
  constructor(ngWords) {
    ngWords = ngWords || {}

    this._warnNGWords = ngWords.warn || []
    this._errorNGWords = ngWords.error || []
  }

  warn(text) {
    return !this._valid(text, this._warnNGWords)
  }

  error(text) {
    return !this._valid(text, this._errorNGWords)
  }

  _valid(text, words) {
    return !words.some(pattern => text.match(pattern))
  }
}

class NGWordForm {
  instances = []

  constructor(params) {
    this.button = null
  }

  add(form) {
    this.instances.push(form)
    this.button = form.button
  }

  valid() {
    return this.instances.every(i => i.permit)
  }

  toggleSubmit() {
    this.button.toggleClass('disabled', !this.valid())
    this.button.prop('disabled', !this.valid())
  }
}

const form = new NGWordForm({})

export default class {
  constructor(params) {
    params = params || {}

    this.form = form
    this.button = $(params.button || '.btn-primary')
    this.toggleButton = params.toggleButton ?? true

    this.validTarget = $(params.validTarget || 'input')
    this.errorField = params.errorField ? $(params.errorField) : this.validTarget.parent().find('.ng-word-error')

    this.permit = true
    this.errorMessage = params.errorMessage || '入力された文字は表示不適切な表現であるため入力できません。'
    this.warnMessage = params.warnMessage || '入力された文字は表示不適切な表現となる可能性があるためご確認ください。'
    this.limitMessage = params.limitMessage || '{limit}文字以内で入力してください'

    this.lengthLimit = this.validTarget.data('length-limit') || params.lengthLimit
    this.lengthMin = this.validTarget.data('length-min') || params.lengthMin

    this.ngWords = new NGWords(params.ngWords)

    this.validTarget.on(
      'focus keypress keyup change autocompletechange autocompleteopen autocompleteclose',
      this._change.bind(this)
    )

    this.button.on('click', this._click.bind(this))
    switch (params.type) {
      case 'telop':
      case 0:
        this.ngWordType = 0
        break
      case 'real_estate':
      case 'realEstate':
      case 1:
        this.ngWordType = 1
        break
      default:
        this.ngWordType = 0
        break
    }

    form.add(this)
  }

  _change() {
    if (this.beforeText === this.validTarget.val()) {
      return
    }
    this.beforeText = this.validTarget.val()

    this.permit = !this._invalid()
    if (this.toggleButton) {
      this.form.toggleSubmit()
    }
  }

  _invalid() {
    if (this.lengthLimit && this.validTarget.val().length > this.lengthLimit) {
      this._showErrorMessage(
        this.limitMessage.replace(/\{limit\}/, this.lengthLimit)
      )
      return true
    }

    if (
      this.lengthMin !== undefined &&
      this.lengthMin !== null &&
      this.validTarget.val().length <= this.lengthMin
    ) {
      return true
    }

    if (this.ngWords.error(this.validTarget.val())) {
      this._showErrorMessage(this.errorMessage)
      return true
    }

    if (this.ngWords.warn(this.validTarget.val())) {
      this._showErrorMessage(this.warnMessage)
    } else {
      this._showErrorMessage('')
    }
    return false
  }

  _showErrorMessage(message) {
    this.errorField.text(message)
  }

  _submit() {
    return this.permit
  }

  // アンカータグ用
  _click() {
    return this.permit
  }
}
