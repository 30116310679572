// @ts-nocheck

import Base from './base'
import Map from './map'
import Item from './item'

export default class extends Base {
  static get nullInstance() {
    return new this({
      x: -1,
      y: -1
    }).setNull()
  }

  static create(data: any, map = null) {
    if(data === null || data === undefined) {
      return this.nullInstance
    }
    if(data instanceof this) {
      return data
    }

    return new this(data)
  }

  constructor(data: any = {}, map = null) {
    super()
    Object.assign(this, data)

    this.map = map
    this.items = (data.items || []).map(i => Item.create(i, this))
  }
}
