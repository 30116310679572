import Place from './place'
import Organization from './organization'

export default class {
  constructor(data = {}) {
    this.id = data.id
    this.name = data.name
    this.settings = data.settings
    this.accesibles = data.accesibles
    this.atbb = data.atbb
    this.contract = data.contract

    this.external_id_labels = data.external_id_labels
    this.typical_external_id_label = data.typical_external_id_label
    this.filter_lists = data.filter_lists || []

    this.organization = new Organization(data.organization)
    this.accesibles = data.accesibles || []
  }

  // FIXME: 最終的に casl-ability に移行する。
  can(op, target_organization_group_id) {
    if (! (target_organization_group_id in this.accesibles)) {
      return false
    }

    const priv = this.accesibles[target_organization_group_id].priv
    switch (op) {
      case 'owner':
        return priv == 'owner'
      case 'write':
        return priv == 'owner' || priv == 'read_write'
      case 'read':
        return true
      default:
        return false
    }

    return false
  }

  fetchSetting(key, default_value = null) {
    return key in this.settings ? this.settings[key] : default_value
  }

  shouldShow(key, default_value = true) {
    const hide_elements = this.fetchSetting('view.hide_elements', [])
    if (hide_elements.includes(key)) {
      return false
    }

    const show_elements = this.fetchSetting('view.show_elements', [])
    if (show_elements.includes(key)) {
      return true
    }

    return default_value
  }

  placeColumns() {
    const column_list = [
      { key: 'column_delete_checkbox', name: '_model.place.child.delete' },
      { key: 'column_status', name: '_model.place.child.status' },
      { key: 'column_name', name: '_model.place.child.name' },
      { key: 'column_attributes', name: '_model.place.child.attributes' },
      { key: 'column_medium', name: '_model.place.child.place_medium' },
      { key: 'column_map', name: '_model.place.child.column_map' },
      { key: 'column_points', name: '_model.place.child.points' },
      { key: 'column_panorama', name: '_model.place.child.panorama' },
      {
        key: 'column_qr',
        name: this.atbb
          ? '_model.place.child.qr_atbb'
          : '_model.place.child.qr',
      },
      { key: 'column_outsourcing_management', name: '_model.place.child.outsourcing_management' },
      { key: 'column_photo_movie', name: '_model.place.child.photomovie' },
      {
        key: 'column_factsheet',
        name: this.atbb
          ? '_model.place.child.factsheet_atbb'
          : '_model.place.child.factsheet',
      },
    ]

    return column_list.filter(column => {
      if (column.key === 'column_photo_movie')
        return this.fetchSetting('photomovie.enabled', false)

      if (column.key === 'column_outsourcing_management')
        return this.fetchSetting('order_sheet.photographing_order.enabled', false)

      return this.shouldShow(column.key)
    })
  }

  externalIdLabel(attribute_id) {
    return this.external_id_labels[attribute_id]
  }

  atbbPlan(target) {
    if (!this.atbb) return true

    switch (target) {
      case 'vr':
        return ['all', 'vr'].includes(this.atbb.vr_plan)
      case 'panorama':
        return ['all', 'panorama'].includes(this.atbb.vr_plan)
      default:
        return false
    }
  }
}
