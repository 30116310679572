const nanobus = require('nanobus')

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject)=> {
      this.reject = reject
      this.resolve = resolve
    })
    .then(({ Vue, store, vm }) => {
      window.Vue = Vue
      window.store = store

      return Promise.resolve({ Vue, store, vm })
    })
  }
}

const dfd = new Deferred()

module.exports = {
  loading: {
    show(message = '') {
      Nurve.bus.emit('nurve-loading', {
        show: true, message: message, spinner: true, icon: ''
      })
    },

    done(message = '') {
      Nurve.bus.emit('nurve-loading', {
        show: true, message: message, spinner: false, icon: 'check'
      })
    },

    failed(message = '') {
      Nurve.bus.emit('nurve-loading', {
        show: true, message: message, spinner: false, icon: 'times'
      })
    },

    hide() {
      Nurve.bus.emit('nurve-loading', {
        show: false
      })
    }
  },

  notice: {
    success(message, delay = 3000) {
      this.show(message, 'success', delay)
    },

    warning(message, delay = 3000) {
      this.show(message, 'warning', delay)
    },

    danger(message, delay = 3000) {
      this.show(message, 'danger', delay)
    },

    show(message, variant = 'success', delay = 3000) {
      Nurve.bus.emit('nurve-notice', { message, variant, delay })
    }
  },

  log: null,

  // Vueコンポーネントと外部間でイベント通知をするためのEventBus
  // 従来は window を EventBus として利用していたが、window を汚染するのは望ましくない。
  // あくまでもVueコンポーネント化の移行のための一時利用であるべき。
  // コンポーネント間の情報のやりとりは Vueイベントや Vuex storeを経由させるべきである。
  bus: new nanobus(),

  ready: {
    promise: dfd.promise,
    resolve: dfd.resolve
  },

  // Vue Rootコンポーネントの $http, $store への参照。
  // Vueコンポーネント外から $http, $store にアクセスできるようにする。
  $store: null,
  $http: null,

  // app/javascripts/packs 以下で定義されていた関数のまとめ先。
  // app/javascripts/packs ファイルの数を減らすため一時的に使用する。
  // 最終的には Vue コンポーネントを定義してそちらに移動する。
  procs: require('./nurve-procs').default
}
