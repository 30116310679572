console.log('setup/expose')

// Rails は expose-loader によってグローバルにexposeする。
import Rails from 'expose-loader?exposes=Rails!@rails/ujs'
Rails.start()

// js-routes を Rail.routes にインポート。
import * as Routes from '~/routes/rails'
Rails.routes = Routes

// jQuery も expose-loader によってグローバルにexposeする。
import 'expose-loader?exposes=$,jQuery!jquery'

import 'expose-loader?exposes=Nurve!~/nurve'
