import OrganizationGroup from './organization_group'

export default class {
  constructor(data = {}) {
    this.id = data.id
    this.name = data.name
    this.role = data.role
    this.permissions = data.permissions || []

    this.normalizeId(
      OrganizationGroup,
      'organization_group',
      'organization_group_id',
      data
    )
  }

  normalizeId(klass, association_name, id_name, data) {
    let klass_obj = null
    if (data[association_name] && data[association_name] instanceof klass) {
      klass_obj = data[association_name]
    } else if (data[association_name]) {
      klass_obj = new klass(data[association_name])
    } else if (data[id_name]) {
      klass_obj = new klass({ id: data[id_name] })
    } else {
      return
    }

    this[association_name] = klass_obj
    this[id_name] = klass_obj.id
  }

  can(op) {
    return this.permissions.includes(op)
  }

  get isAdmin() {
    return this.role == 'admin'
  }
}
