// app/javascripts/packs ファイルの数を減らすため一時的移動先。
// 最終的には Vue コンポーネントを定義してそちらに移動する。

import loadImage from 'image-promise'

import delay from 'delay'
import Sortable from 'sortablejs'
import moment from 'moment'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

import NGWordForm from '~/ng_word_form'
import waitUntilRefleshed from '~/closes'

import '~/common/styles/place-medium.scss'
import '~/common/styles/easy-registrations.scss'

export default {
  mapsEditInit: async () => {
    const image = document.querySelector('#medium-image')
    await loadImage(image)

    const cropper = new Cropper(image, {
      zoomable: false,
      checkCrossOrigin: false,
      autoCropArea: 1.0,
      ready: () => {
        $('#btn-register').prop('disabled', '')
      }
    })

    $('#btn-register').on('click', () => {
      Nurve.loading.show()

      const crop_data = cropper.getData()
      $('#x1').val(crop_data.x)
      $('#y1').val(crop_data.y)
      $('#x2').val(crop_data.x + crop_data.width)
      $('#y2').val(crop_data.y + crop_data.height)

      $('form').submit()
    })
  },

  easyRegistrationsInit: async () => {
    const image = document.querySelector('#medium-image')
    await loadImage(image)

    const cropper = new Cropper(image, {
      zoomable: false,
      checkCrossOrigin: false,

      // 間取り図からの取り込みの場合は 1.0 を指定して、全体が選択されるようにする。
      // （デフォルトは0.8）
      autoCropArea: gon.easy_registration.autoCropArea,

      ready: () => {
        $('#btn-crop').prop('disabled', '')

        // 間取り図から登録の場合に必要。
        const crop_data = cropper.getData()
        $('#x1').val(0)
        $('#y1').val(0)
        $('#x2').val(crop_data.width)
        $('#y2').val(crop_data.height)
      }
    })

    $('#btn-crop').on('click', async () => {
      Nurve.loading.show()

      const crop_data = cropper.getData()
      $('#x1').val(crop_data.x)
      $('#y1').val(crop_data.y)
      $('#x2').val(crop_data.x + crop_data.width)
      $('#y2').val(crop_data.y + crop_data.height)

      // croppingがサクッと終わってしまうと Loading のダイアログが出現する前に
      // Nurve.loading.hide() が呼ばれてしまい、Loading が残ってしまう。
      // それを防ぐため 1500msec 後に resolve する Promise と並走させて最低 1500msec 間が開くようにする。
      // FIXME: いろいろ使えそうなので共通ライブラリ化したほうが良いかもしれない。
      const promise1 = new Promise(resolve => {
        setTimeout(() => { resolve() }, 1500)
      })
      const promise2 = new Promise(resolve => {
        $('#preview').attr('src', cropper.getCroppedCanvas().toDataURL('image/jpeg'))
        resolve()
      })
      await Promise.all([promise1, promise2])

      Nurve.loading.hide()
      $('#error').empty()
      $('.mode').removeClass('crop').addClass('input')
    })

    $('#btn-undo').on('click', () =>
      $('.mode').removeClass('input').addClass('crop')
    )

    $('#btn-register').on('click', () => {
      Nurve.loading.show()
    })

    $('select[name=slug]').on('change', ({ target }) => {
      $('#error').empty()

      const slug = $(target).val()
      for (let level = 1; level <= 2; level++) {
        const name = gon.attributes[slug]['names'][level]
        $(`#attribute_l${level}`).text(name)

        const external_id = gon.attributes[slug]['external_ids'][level]
        $(`#external_id_label_l${level}`).text(external_id)

        // 非表示にする場合は input にも disabled を付与してフォームデータから除外する。
        $(`#external_id_l${level}`).toggle(!!external_id)
        $(`#external_id_l${level} input`).attr('disabled', !external_id)
      }
    }).trigger('change')

    const currentOrganizationGroup = Nurve.$store.getters['contexts/currentOrganizationGroup']
    const ngWordsList = currentOrganizationGroup.fetchSetting('view.ng_words')

    new NGWordForm({
      validTarget: '#parent-name',
      button: '#btn-register',
      errorField: '#parent-name-error',
      lengthMin: 0,
      ngWords: ngWordsList.place.l1,
    })
    new NGWordForm({
      validTarget: '#child-name',
      button: '#btn-register',
      errorField: '#child-name-error',
      ngWords: ngWordsList.place.l2,
    })
  },

  mapsOrdersShow: () => {
    const sortableParent = document.getElementById('sortable')
    Sortable.create(sortableParent, {
      handle: '.handle',
      animation: 150
    })
  },

  placeShowInit: () => {
    $('#panorama-reload').on('click', function() {
      $('#panorama').get(0).contentDocument.location.reload(true)
    })
    $('#photomovie-reload').on('click', function() {
      $('#photomovie').get(0).contentDocument.location.reload(true)
    })

    // 直URLで画面を開いた時、Chromeでは閉じることができないので物件一覧に戻るようにした
    window.name === "" ? $("#btn_close_detail_place_window").remove() : $("#btn-back-places").remove()
  },

  pointUnloadInit: async () => {
    const now = moment()
    const place_id = location.pathname.split('/')[2]
    const map_id = location.pathname.split('/')[4]

    const url = Rails.routes.unload_page_place_map_points_path(place_id, map_id, { from: now.format() })
    await Nurve.$http.get(url)
  },

  mapsCreateInit: () => {
    const buttons = document.getElementsByClassName('upload-map-button')
    for(let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', function (event) {
        event.preventDefault()
        const fileField = event.target.parentElement.querySelector(".upload-map-field")
        fileField.click()
        return false // IE向けトリック
      }, false)
    }

    const fileFields = document.getElementsByClassName('upload-map-field')
    for(let i = 0; i < fileFields.length; i++) {
      fileFields[i].addEventListener('change', function (event) {
        const formData = new FormData();
        formData.append('medium', (event.target.files || event.dataTransfer.files)[0]);

        Nurve.$http.post(event.target.dataset.url, formData)
        .then(response => {
          const notice = event.target.parentElement.querySelector(".upload-map-notice")
          notice.innerHTML = 'アップロードされました。2秒後にリロードします……'
        })
        .catch(error => {
          console.error(error);
          alert('アップロードが失敗しました。サポートされている画像かどうかをご確認ください。')
        })
        .finally(async () => {
          await delay(2000)
          document.location.reload()
        })

      }, false)
    }
  },

  mapsIndexInit: () => {
    const inputs = document.getElementsByClassName('map-name')
    for(let i = 0; i < inputs.length; i++) {
      const input = inputs[i]
      switchIconDone(input)
      input.addEventListener('change', putChangedName)
      input.addEventListener('input', putChangedName)
    }

    $('button.move_to').on('confirm:complete', function(e, response) {
      if(!e.detail[0]) return

      const element = $(e.currentTarget)
      const to_map_id = $(element.data('select-id')).val()

      const url = $(e.currentTarget).data('url') + '?to_map_id=' + to_map_id
      document.location.href = url
    })
  },

  atbbOrganizationGroupInit: () => {
    const companyNameText = document.querySelector('#atbb_organization_group_company_name')
    const importCheckbox = document.querySelector('#import')
    const startImportButton = document.querySelector('#start-import')

    const toggleStartImportButton = () => {
      const enable = (companyNameText.value.trim() != '') && importCheckbox.checked && startImportButton.dataset.started == ''
      startImportButton.disabled = !enable
      startImportButton.toggleAttribute('disabled', !enable)
    }

    companyNameText.addEventListener('input', toggleStartImportButton)
    importCheckbox.addEventListener('change', toggleStartImportButton)
    toggleStartImportButton()

    startImportButton.addEventListener('click', () => {
      console.log('clicked')
      // ボタンの二度押し防止のため、画面をリロードするまでボタンを無効化させる。
      startImportButton.dataset.started = '1'
      toggleStartImportButton()

      Nurve.$http.post(startImportButton.dataset.url).then(() => {
        Nurve.notice.success('インポートを開始しました。')
      })
    })
  },

  adminAccountsEditInit: () => {
    const organizationSelect = document.getElementById("account_organization_id")
    organizationSelect.addEventListener('change', loadOrganizationGroupOptions)
    loadOrganizationGroupOptions()
  },

  pairedAccountsFormInit: () => {
    const redirect = () => {
      location.href = location.pathname +
        '?store_id=' + $('#store_id').val() +
        '&device_organization_id=' + $('#device_organization_id').val() +
        '&place_organization_id=' + $('#place_organization_id').val() +
        '&account_id=' + $('#anywhere_paired_account_account_id').val() +
        '&device_id=' + $('#anywhere_paired_account_device_id').val() +
        '&viewer_entry_point_id=' + $('#anywhere_paired_account_viewer_entry_point_id').val()
    }

    $("#store_id").change(redirect)
    $("#device_organization_id").change(redirect)
    $("#place_organization_id").change(redirect)
  },

  organizationGruopSelectInit: () => {
    const organizationSelect = document.getElementById('organization_id')

    organizationSelect.addEventListener('change', replaceOrganizationGroupOptions)
    if (gon.warehouses.length > 0) {
      const returnToWarehouseButton = document.getElementById('btn-return-to-warehouse')
      const selectWarehouseButton = document.getElementById('btn-select-warehouse')

      returnToWarehouseButton.addEventListener('click', () => {
        if (gon.warehouses.length > 1) {
          $('#select-warehouse-modal').modal('show')
        } else {
          returnToWarehouse(gon.warehouses[0].organization_group_id)
        }
      })
      selectWarehouseButton.addEventListener('click', () => {
        const organizationGroupRadio = document.querySelector('input[name=organization_group_id]:checked')
        if (organizationGroupRadio) {
          returnToWarehouse(organizationGroupRadio.value)
        }
      })
    }

    replaceOrganizationGroupOptions()
  },

  kittingNewInit: () => {
    const organizationSelect = document.getElementById("terminal_kittinger_organization_id");
    organizationSelect.addEventListener("change", kittingLoadOrganizationGroupOptions);
    kittingLoadOrganizationGroupOptions()
  },

  waitUntilRefleshed: waitUntilRefleshed
}

const switchIconDone = function(input) {
  input.parentNode.querySelector('.fa-check').style.display = 'inline'
  input.parentNode.querySelector('.img-fluid').style.display = 'none'
}

const switchIconLoading = function(input) {
  input.parentNode.querySelector('.fa-check').style.display = 'none'
  input.parentNode.querySelector('.img-fluid').style.display = 'block'
}

const putChangedName = async function(event) {
  const input = event.target
  switchIconLoading(input)
  const name = input.value

  const putPath = input.dataset.putPath
  await Nurve.$http.put(putPath, { map: { name }})

  switchIconDone(input)
}

function replaceOrganizationGroupOptions() {
  const organizationSelect = document.getElementById('organization_id')
  const organizationGroupSelect = document.getElementById('device_organization_group_id')
  const organizationId = organizationSelect.value
  const organizationGroups = gon.organization_groups_group_by_organization_id[organizationId]

  $('#device_organization_group_id option').remove()
  organizationGroups.forEach((organizationGroup) => {
    const option = document.createElement('option')
    option.value = organizationGroup.id
    option.text = organizationGroup.id_and_name
    if (organizationGroupSelect.dataset.selected === option.value) {
      option.selected = true
    }

    organizationGroupSelect.appendChild(option)
  })
}

function returnToWarehouse(organizationGroupId) {
  const organizationId = gon.warehouses.find(warehouse => warehouse.organization_group_id === Number(organizationGroupId)).organization_id
  const organizationSelect = document.getElementById('organization_id')
  const organizationGroupSelect = document.getElementById('device_organization_group_id')
  organizationSelect.value = organizationId
  replaceOrganizationGroupOptions()
  organizationGroupSelect.value = organizationGroupId
}

function loadOrganizationGroupOptions() {
  const organizationSelect = document.getElementById("account_organization_id")
  const organizationGroupSelect = document.getElementById("account_organization_group_id")
  const organizationId = organizationSelect.value
  const organizationGroups = gon.organization_groups_group_by_organization_id[organizationId]

  $("#account_organization_group_id option").remove()

  organizationGroups.forEach(organizationGroup => {
    const option = document.createElement("option")
    option.value = organizationGroup.id
    option.text = organizationGroup.id_and_name
    if (organizationGroupSelect.dataset.selected === option.value) {
      option.selected = true
    }

    organizationGroupSelect.appendChild(option)
  })
}

function kittingLoadOrganizationGroupOptions() {
  const organizationSelect = document.getElementById("terminal_kittinger_organization_id")
  const organizationGroupSelect = document.getElementById("terminal_kittinger_organization_group_id")
  const organizationId = organizationSelect.value
  const organizationGroups = gon.organization_groups_group_by_organization_id[organizationId]

  $("#terminal_kittinger_organization_group_id option").remove()

  organizationGroups.forEach(organizationGroup => {
    const option = document.createElement("option")
    option.value = organizationGroup.id
    option.text = organizationGroup.name
    if (organizationGroupSelect.dataset.selected === option.value) {
      option.selected = true
    }

    organizationGroupSelect.appendChild(option)
  })
}
